import {type FC, useCallback, useMemo} from 'react';
import {CoinSummary} from '@/app/interfaces/report';
import {isPositive} from '@/app/modules/helpers';
import {useTranslation} from '@/app/modules/hooks/useTranslation';

interface Props {
    title: string;
    subtitle: string;
    summary: CoinSummary;
}

const CoinTable: FC<Props> = ({title, subtitle, summary}) => {
    const _ = useTranslation();
    const tableData = useMemo(() => ([
        {
            metric: _('REPORT.TOTAL_DEPOSITS'),
            coinAmount: summary.transactions.total.coinAmount,
            usd: summary.transactions.total.usd,
            try: summary.transactions.total.fiat,
            count: summary.transactions.count,
        },
        {
            metric: _('REPORT.TOTAL_WITHDRAWALS'),
            coinAmount: summary.withdrawals.total.coinAmount,
            usd: summary.withdrawals.total.usd,
            try: summary.withdrawals.total.fiat,
            count: summary.withdrawals.count,
        },

        {
            metric: _('REPORT.TOTAL_FEES'),
            coinAmount: summary.fees.coinAmount,
            usd: summary.fees.usd,
            try: summary.fees.fiat,
            count: '-',
        },


    ]), [summary, _]);

    const getColor = useCallback((val: string) => {
        if (parseFloat(val) === 0) {
            return 'text-gray-900';
        }
        return isPositive(val) ? 'text-success' : 'text-danger';
    }, []);
    return (
        <div className="card card-flush mb-xl-10 mb-5">
            <div className="card-header pt-5">
                <h3 className="card-title align-items-start flex-column">
                    <span className="card-label fw-bold text-gray-800">{title}</span>

                    <span className="text-gray-500 pt-1 fw-semibold fs-6">{subtitle}</span>
                </h3>

            </div>
            <div className="card-body py-0">
                <div className="table-responsive">
                    <table className="table table-row-dashed align-middle gs-0 gy-4">
                        <thead>
                        <tr className="fs-7 fw-bold border-0 text-gray-500">
                            <th className="">{_('REPORT.TABLE.METRIC')}</th>
                            <th className=" text-end pe-0">{_('REPORT.TABLE.COIN_AMOUNT')}</th>
                            <th className="text-end">USD</th>
                            <th className="text-end ">TRY</th>
                            <th className="text-end ">{_('REPORT.TABLE.TX_COUNT')}</th>
                        </tr>
                        </thead>
                        <tbody>
                        {tableData.map((data, index) => (
                            <tr>
                                <td className="text-gray-800 fw-bolder text-hover-primary mb-1 fs-6">
                                    {data.metric}
                                </td>

                                <td className="text-right">
                                    <span className={`text-gray-900 fw-bold fs-6`}>{data.coinAmount}</span>
                                </td>
                                <td className="text-right">
                                    <span className={`text-gray-900 fw-bold fs-6`}>{data.usd}</span>
                                </td>
                                <td className="text-right">
                                    <span className={`text-gray-900 fw-bold fs-6`}>{data.try}</span>
                                </td>
                                <td className="text-right">
                                    <span className="text-gray-900 fw-bold fs-6">{data.count}</span>
                                </td>

                            </tr>
                        ))}
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    );
};

export default CoinTable;